import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import { Flex, Box, Text } from "theme-ui";
import PictureSet from "../components/PictureSet";
import imgSet14 from "../imgsets/imgset14";
import * as palette from "../const/palette";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Corporate Identity" keywords={[]} mdxType="SEO" />
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet14} mdxType="PictureSet" />
    </Wide>
    <h1>{`Corporate Identity`}</h1>
    <h2>{`Farbpalette`}</h2>
    <Box mdxType="Box">
  <Flex sx={{
        alignItems: "center"
      }} mdxType="Flex">
    <Text sx={{
          width: "106px",
          pb: 2
        }} mdxType="Text">Heading h1:</Text>
    <Box sx={{
          width: "200px",
          background: "#fff",
          color: palette.HEADING,
          p: 2,
          mb: 2,
          textAlign: "center",
          fontSize: 2
        }} mdxType="Box">
      {palette.HEADING}
    </Box>
  </Flex>
  <Flex sx={{
        alignItems: "center"
      }} mdxType="Flex">
    <Text sx={{
          width: "106px",
          pb: 2
        }} mdxType="Text">Heading h2:</Text>
    <Box sx={{
          width: "200px",
          background: "#fff",
          color: palette.PRIMARY,
          p: 2,
          mb: 2,
          textAlign: "center",
          fontSize: 2
        }} mdxType="Box">
      {palette.PRIMARY}
    </Box>
  </Flex>
  <Flex sx={{
        alignItems: "center"
      }} mdxType="Flex">
  <Text sx={{
          width: "106px",
          pb: 2
        }} mdxType="Text">Text:</Text>
  <Box sx={{
          width: "200px",
          background: "#fff",
          color: palette.TEXT,
          p: 2,
          mb: 2,
          textAlign: "center",
          fontSize: 2
        }} mdxType="Box">
    {palette.TEXT}
  </Box>
      </Flex>
  <Flex sx={{
        alignItems: "center"
      }} mdxType="Flex">
    <Text sx={{
          width: "106px",
          pb: 2
        }} mdxType="Text">Primary:</Text>
    <Box sx={{
          width: "200px",
          background: palette.PRIMARY,
          color: "#fff",
          p: 2,
          mb: 2,
          textAlign: "center"
        }} mdxType="Box">
      {palette.PRIMARY}
    </Box>
  </Flex>
  <Flex sx={{
        alignItems: "center"
      }} mdxType="Flex">
    <Text sx={{
          width: "106px",
          pb: 2
        }} mdxType="Text">Secondary:</Text>
    <Box sx={{
          width: "200px",
          background: palette.SECONDARY,
          color: "#222",
          p: 2,
          mb: 2,
          textAlign: "center"
        }} mdxType="Box">
      {palette.SECONDARY}
    </Box>
  </Flex>
  <Flex sx={{
        alignItems: "center"
      }} mdxType="Flex">
    <Text sx={{
          width: "106px",
          pb: 2
        }} mdxType="Text">Accent:</Text>
    <Box sx={{
          width: "200px",
          background: palette.ACCENT,
          color: "#222",
          p: 2,
          mb: 2,
          textAlign: "center"
        }} mdxType="Box">
      {palette.ACCENT}
    </Box>
  </Flex>
  <Flex sx={{
        alignItems: "center"
      }} mdxType="Flex">
    <Text sx={{
          width: "106px",
          pb: 2
        }} mdxType="Text">Highlight:</Text>
    <Box sx={{
          width: "200px",
          background: palette.HIGHLIGHT,
          color: "#fff",
          p: 2,
          mb: 2,
          textAlign: "center"
        }} mdxType="Box">
      {palette.HIGHLIGHT}
    </Box>
  </Flex>
  <Flex sx={{
        alignItems: "center"
      }} mdxType="Flex">
    <Text sx={{
          width: "106px",
          pb: 2
        }} mdxType="Text">Muted:</Text>
    <Box sx={{
          width: "200px",
          background: palette.MUTED,
          color: "#fff",
          p: 2,
          mb: 2,
          textAlign: "center"
        }} mdxType="Box">
      {palette.MUTED}
    </Box>
  </Flex>
    </Box>
    <h2>{`Schrift`}</h2>
    <Flex mdxType="Flex">
  <Text sx={{
        width: "106px"
      }} mdxType="Text">Tittilium Web:</Text>{" "}
      <p><a parentName="p" {...{
          "href": "https://fonts.google.com/specimen/Titillium+Web",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://fonts.google.com/specimen/Titillium+Web`}</a></p>
    </Flex>
    <Flex mdxType="Flex">
  <Text sx={{
        width: "106px"
      }} mdxType="Text">Commissioner:</Text>{" "}
      <p><a parentName="p" {...{
          "href": "https://fonts.google.com/specimen/Commissioner",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://fonts.google.com/specimen/Commissioner`}</a></p>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      